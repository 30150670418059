import React from 'react';
import { Link } from 'gatsby';
import ImgCanDo1 from 'src/images/payments/what-paidy-can-do/can-do-1.png';
import ImgCanDo2 from 'src/images/payments/what-paidy-can-do/can-do-2.png';
import ImgCanDo3 from 'src/images/payments/what-paidy-can-do/can-do-3.png';
import ImgCanDo65k from './can-do-65k.png';
import styles from './PaymentWhatPaidyCanDo.module.scss';

const TITLE = 'ペイディが選ばれる理由';

const DATA = [
  {
    key: 'can-do-2',
    img: ImgCanDo2,
    text: (
      <>
        <h2 className={styles.headline}>
          分割手数料無料*の
          <br />
          3・6・12回あと払い
        </h2>
        <p className={styles.description}>
          分割手数料無料*で、お支払いを3・6・12回に分けることができます。お支払い総額はそのまま、月々のお支払いを自由に調整できるので、自分のペースにあわせてお支払いいただけます。{' '}
        </p>
        <Link className={styles.link} to="/npay">
          6・12回あと払いが使えるお店を見る
        </Link>
        <p className={styles.notes}>
          *口座振替・銀行振込のみ無料
          <br />
          <br />
          ※選択できる支払い回数は加盟店により異なります。
          <br />
          <br />
          ※3回あと払いは1回のお買い物につき、Amazonは3円以上、その他のショップでは3,000円以上のお支払いで利用可能です。6回あと払いは1回のお買い物につき、Amazonは6円以上、その他のショップでは6,000円以上のお支払いで利用可能です。12回あと払いは1回のお買い物につき、12,000円以上でご利用いただけます。一部のショップでは3・6・12回あと払いはご利用いただけません。
          <br />
          <br />
          ※ペイディカードで3回あと払いを利用する場合は、店舗での購入時に分割払いを選択せず、一括払いで購入後、ペイディアプリから3回あと払いに変更してください。
        </p>
      </>
    ),
  },
  {
    key: 'can-do-65k',
    img: ImgCanDo65k,
    text: (
      <>
        <h2 className={styles.headline}>ご利用可能額の確認</h2>
        <p className={styles.description}>
          毎月のご利用可能額がいつでも確認できるようになります。今月残りいくらペイディを使えるかがわかるので、お買い物をかしこく楽しめます。
        </p>
      </>
    ),
  },
  {
    key: 'can-do-3',
    img: ImgCanDo3,
    text: (
      <>
        <h2 className={styles.headline}>使い過ぎを防止</h2>
        <p className={styles.description}>
          予算設定機能や、いつでもすぐに確認できる利用履歴、利用通知メールなど、便利な機能で使い過ぎを防止。
        </p>
        <Link className={styles.link} to="/guide#budget">
          使い過ぎ防止の詳細を見る
        </Link>
      </>
    ),
  },
  {
    key: 'can-do-1',
    img: ImgCanDo1,
    text: (
      <>
        <h2 className={styles.headline}>ペイディカードでどこでも</h2>
        <p className={styles.description}>
          Visaマークのあるお店でクレジットカードとしてお買い物を楽しめます。ネットショップで使えるバーチャルカードは最短2分で無料・即日発行。さらにリアルカードを発行すると、街のお店でも使えます。
        </p>
        <Link className={styles.link} to="/paidycard">
          ペイディカードの詳細を見る
        </Link>
      </>
    ),
  },
];

export default function PaymentWhatPaidyCanDo({ title = TITLE, data = DATA }) {
  return (
    <section className={styles.section} id="what-app-can-do">
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.container}>
        {data.map((item, index) => (
          <div className={styles.item} key={item.key}>
            <img
              src={item.img}
              alt={`how-to-use-${index}`}
              className={styles.img}
            />
            {item.text}
          </div>
        ))}
      </div>
    </section>
  );
}
