import React from 'react';
import PaymentHeroBanner from 'src/components/Payments/PaymentHeroBanner';
import PaymentHowToUse from 'src/components/Payments/PaymentHowToUse';
import PaymentMoreConvenientWithTheApp from 'src/components/Payments/PaymentMoreConvenientWithTheApp';
import PaymentMethod from 'src/components/Payments/PaymentMethod';
import PaymentHowToPay from 'src/components/Payments/PaymentHowToPay';
import PaymentWhatPaidyCanDo from 'src/components/Payments/PaymentWhatPaidyCanDo';
import { SITE_METADATA } from '../constants';
import Layout from '../components/Layout/Layout';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';

const BREADCRUMB_LEVELS = [
  { path: '/', title: 'トップ' },
  { path: '/payments/', title: '使い方' },
];

const PaymentPage = () => {
  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.payments.title,
        description: SITE_METADATA.payments.description,
      }}
    >
      <PaymentHeroBanner />
      <Breadcrumb customLevels={BREADCRUMB_LEVELS} backgroundColor="#ffffff" />
      <PaymentHowToUse />
      <PaymentMoreConvenientWithTheApp />
      <PaymentMethod />
      <PaymentHowToPay />
      <PaymentWhatPaidyCanDo />
    </Layout>
  );
};

export default PaymentPage;
