import React from 'react';
import styles from './PaymentHeroBanner.module.scss';

export default function PaymentHeroBanner() {
  return (
    <section className={styles.section}>
      <div className={styles.background}>
        <h1 className={styles.title}>使い方</h1>
      </div>
    </section>
  );
}
