import React from 'react';
import { Hidden } from '@material-ui/core';
import ImgMethod1 from 'src/images/payments/method/method-1.svg';
import ImgMethod2 from 'src/images/payments/method/method-2.svg';
import ImgMethod3 from 'src/images/payments/method/method-3.svg';
import ImgMethod4 from 'src/images/payments/method/method-4.svg';
import styles from './PaymentMethod.module.scss';

const TITLE = 'お支払い方法';

const DATA = [
  {
    key: 'payment-method-1',
    img: ImgMethod1,
    text: (
      <>
        １か月に何回お買い物をしても、
        <Hidden smUp>
          <br />
        </Hidden>
        お支払いはまとめて翌月でOK。
      </>
    ),
  },
  {
    key: 'payment-method-2',
    img: ImgMethod2,
    text: (
      <>
        ペイディアプリの「アカウント」から<Hidden xsDown>、</Hidden>
        <Hidden smUp>
          <br />
        </Hidden>
        「お支払い方法」を設定する。
      </>
    ),
  },
  {
    key: 'payment-method-3',
    img: ImgMethod3,
    text: (
      <>
        翌月1日～5日の間にメールとSMSで
        <Hidden smUp>
          <br />
        </Hidden>
        ご請求金額をお知らせ。
      </>
    ),
  },
  {
    key: 'payment-method-4',
    img: ImgMethod4,
    text: '翌月27日までにお支払いください。',
  },
];

export default function PaymentMethod({ title = TITLE, data = DATA }) {
  return (
    <section className={styles.section}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.container}>
        {data.map((item, index) => (
          <div className={styles.item} key={item.key}>
            <img
              src={item.img}
              alt={`payment-method-${index}`}
              className={styles.img}
            />
            <div className={styles.textWrapper}>
              <p className={styles.text}>{item.text}</p>
              {item.textSmall && (
                <p className={styles.textSmall}>{item.textSmall}</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
