import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import ImgHowToPay1 from 'src/images/payments/how-to-pay/how-to-pay-1.png';
import ImgHowToPay2 from 'src/images/payments/how-to-pay/how-to-pay-2.png';
import ImgHowToPay3 from 'src/images/payments/how-to-pay/how-to-pay-3.png';
import ImgHowToPay4 from 'src/images/payments/how-to-pay/how-to-pay-4.png';
import LinearCTAButton from 'src/components/LinearCTAButton';
import styles from './PaymentHowToPay.module.scss';

const TITLE = '自由に選べるお支払い方法';

const DATA = [
  {
    key: 'how-to-pay-1',
    img: ImgHowToPay1,
    text: 'コンビニ払い',
    to: '/guide#payment-convenience-store',
  },
  {
    key: 'how-to-pay-2',
    img: ImgHowToPay2,
    text: '銀行振込',
    to: '/guide#payment-bank-transfer',
  },
  {
    key: 'how-to-pay-3',
    img: ImgHowToPay3,
    text: '口座振替',
    to: '/guide#payment-account-transfer',
  },
  {
    key: 'how-to-pay-4',
    img: ImgHowToPay4,
    text: 'すぐ払い',
    to: '/guide#payment-paynow',
  },
];

export default function PaymentHowToPay({
  title = TITLE,
  data = DATA,
  noCTAButton,
  customClassName = {},
}) {
  return (
    <section
      className={cx(styles.section, customClassName.section)}
      id="how-to-pay"
    >
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.container}>
        {data.map((item, index) => (
          <Link to={item.to} className={styles.item} key={item.key}>
            <img
              src={item.img}
              alt={`how-to-pay-${index}`}
              className={styles.img}
            />
            <p className={styles.text}>{item.text}</p>
          </Link>
        ))}
      </div>
      {!noCTAButton && (
        <LinearCTAButton text="お支払い方法の詳細を見る" to="/guide#payment" />
      )}
    </section>
  );
}
